import React from 'react'
import { NotFoundContent, NotFoundIcon, NotFoundText } from './styledComponents'
import emptyReward from '../../data/assets/icons/emptyreward.svg'

const RewardsNotFound: React.FC = () => {
  return (
    <NotFoundContent>
      <NotFoundIcon src={emptyReward} />
      <NotFoundText>
        Para ver tus bonificaciones primero elige el uno de tus Modeloramas.
      </NotFoundText>
    </NotFoundContent>
  )
}

export default RewardsNotFound
