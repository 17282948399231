// Packages
import React from 'react'

// Components
import WithPrivateRoute from '../../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../../components/SEO'
import RewardsReportGraphicsContainer from '../../../containers/RewardsReportGraphicsContainer'

import LayoutMain from '../../../layout'

const Rewards: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Gráficas Bonificaciones" />
      <WithPrivateRoute component={RewardsReportGraphicsContainer} />
    </LayoutMain>
  )
}

export default Rewards
