import styled from 'styled-components'

export const NotFoundContent = styled.div`
  margin-top: 8em;
  text-align: center;
  padding: 2em;
`

export const NotFoundIcon = styled.img`
  width: 10em;
`

export const NotFoundText = styled.p`
  padding: 2em 0 0 0;
  font-size: 16px;
  color: #828282;
`
