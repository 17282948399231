// Packages
import React, { useState, useEffect } from 'react'
import { Plugin } from 'chart.js'
import { LeftOutlined } from '@ant-design/icons'
import { navigate } from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'
// Styled Components
import {
  StyledRewardsButtonBack,
  StyledGraphicsContainer
} from './styledComponents'
import { StyledReportTitle } from '../FilterSalesReportContainer/styledComponents'
// Components
import CustomButton from '../../components/Storybook/customButton'
import RewardsNotFound from '../../components/RewardsNotFound'
import Graphics from '../../components/Storybook/Graphics'
import Loading from '../../components/Shared/Loading'
import { StoresSelect } from '../../components/StoresSelect'
// Utils
import { setInfoOverBar, setFormatAxisY, SET_DATA_GRAPHIC } from './utils'
import { removeSelect } from '../../components/StoresSelect/utils'
import { getParameter } from '../../utils/queryString'
// Reducers
import { reducersTypes } from '../../redux/reducers'
import { callProfileRedux } from '../ProfileContainer'
// Actions
import { saveStore } from '../../redux/ducks/salesReport/actionsCreators'
import { requestDataRewards } from '../../redux/ducks/rewardsGraphicsReport/actionsCreators'
// Lodash
import { findIndex, isEmpty, orderBy } from 'lodash'

const RewardsReportGraphicsContainer: React.FC = () => {
  let isMawEnable = false

  const month = new Date().getMonth().toString()
  const year = new Date().getFullYear().toString()
  const storeID = getParameter('storeId')
  const [dataGraphics, setDataGraphics] = useState([])
  const [dataMonths, setDataMonths] = useState([])
  const { user_data } = useSelector(callProfileRedux)
  const { rewards_data, isLoading } = useSelector(
    (state: reducersTypes) => state.rewards_report
  )
  const dispatch = useDispatch()

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 12,
            weight: '600'
          },
          color: '#434343',
          stepSize: 5000
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: setFormatAxisY,
          font: {
            size: 12,
            weight: '600'
          },
          color: '#434343',
          maxTicksLimit: 7
        },
        grace: '11%'
      }
    }
  }

  const plugins: Plugin[] = [
    {
      id: 'tooltipRewards',
      afterDraw: setInfoOverBar
    }
  ]

  useEffect(() => {
    if (rewards_data.getBonusReport.length > 2) {
      const dataOrderedByYearMonth = orderBy(
        rewards_data.getBonusReport,
        ['year', 'month'],
        ['asc', 'asc']
      )

      const { DATA_GRAPHICS, DATA_MONTHS } = SET_DATA_GRAPHIC(
        dataOrderedByYearMonth
      )
      setDataGraphics(DATA_GRAPHICS)
      setDataMonths(DATA_MONTHS)
    }
  }, [rewards_data])

  useEffect(() => {
    dispatch(
      saveStore(storeID ? storeID : user_data.getPartner?.Store[0].StoreID)
    )
    if (storeID) {
      dispatch(requestDataRewards({ storeID: storeID, month, year }))
    }
  }, [storeID])

  const onChangeStore = (value: string): void => {
    dispatch(saveStore(value))
    removeSelect()
    navigate(`/reports/rewards/graphics?storeId=${value}`)
  }

  if (user_data && user_data.getProfile && storeID) {
    const findIndexPerStoreID = findIndex(
      user_data.getProfile.stores,
      (val: any) => val.storeID === storeID
    )
    const getMawEnableValue =
      findIndexPerStoreID !== -1
        ? user_data.getProfile.stores[findIndexPerStoreID].storeType.mawEnable
        : 0

    isMawEnable = getMawEnableValue === 0 ? false : true
  }

  const body = (
    <>
      <StoresSelect
        store={storeID ? storeID : user_data.getProfile?.stores[0].storeID}
        stores={user_data.getProfile?.stores}
        onChange={onChangeStore}
      />
      <StyledRewardsButtonBack>
        <CustomButton
          id="button-icon-back-sales-report"
          name="buttonIconBackSalesReport"
          className="button-icon-back-sales-report"
          dataTestId="data-test-button-icon-back-sales-report"
          icon={<LeftOutlined />}
          onClick={() => navigate(`/reports/rewards?storeId=${storeID}`)}
        />
        <StyledReportTitle>Reporte de bonificaciones</StyledReportTitle>
      </StyledRewardsButtonBack>
      {rewards_data.getBonusReport.length > 2 ? (
        <>
          <StyledGraphicsContainer>
            {!isEmpty(dataGraphics) && !isEmpty(dataMonths) && (
              <Graphics
                isMawEnable={isMawEnable}
                dataGraphics={dataGraphics}
                months={dataMonths}
                options={options}
                plugins={plugins}
              />
            )}
          </StyledGraphicsContainer>
        </>
      ) : (
        <RewardsNotFound />
      )}
    </>
  )

  return (
    <>
      {!isLoading && body}
      {isLoading && <Loading data-testid="icon-loading" />}
    </>
  )
}

export default RewardsReportGraphicsContainer
