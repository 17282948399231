// Packages
import styled from 'styled-components'

interface StyledLegendShapeTypes {
  colorShape: any
}

export const StyledGraphicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  padding-bottom: 27px;
  margin: 22px 0;
  canvas {
    width: 90% !important;
    margin-top: 15px;
  }
`

export const StyledTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`
export const StyledTitleGraphic = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #434343;
  margin: 9px 0 10px 14px;
`

export const StyledLegendContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const StyledLegend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StyledLegendShape = styled.div<StyledLegendShapeTypes>`
  width: 26px;
  height: 4px;
  border-radius: 2px;
  margin: 0 7px 0 21px;
  background-color: ${({ colorShape }) => colorShape};
`

export const StyledLabelLegend = styled.p`
  margin: 0;
  font-size: 9px;
  font-weight: 600;
  color: #434343;
  text-transform: capitalize;
`
