// Packages
import React from 'react'
import { Chart, Plugin, registerables } from 'chart.js'
import { Bar } from 'react-chartjs-2'

// Styled Compoents
import {
  StyledGraphicsContainer,
  StyledTitleContainer,
  StyledTitleGraphic,
  StyledLegendContainer,
  StyledLegend,
  StyledLegendShape,
  StyledLabelLegend
} from './styledComponents'

interface GraphicsTypes {
  months: any
  dataGraphics: any
  options?: any
  plugins: Plugin[]
  isMawEnable: boolean
}

const Graphics: React.FC<GraphicsTypes> = ({
  months,
  dataGraphics,
  options,
  plugins,
  isMawEnable
}) => {
  Chart.register(...registerables)
  return (
    <>
      {Object.keys(dataGraphics).map((keyGraphic, idx) => {
        if (keyGraphic === 'beerU' && !isMawEnable) return null
        return (
          <StyledGraphicsContainer key={`${keyGraphic}-${idx}`}>
            <StyledTitleContainer>
              <StyledTitleGraphic>
                {dataGraphics[keyGraphic].label}
              </StyledTitleGraphic>
            </StyledTitleContainer>
            <StyledLegendContainer>
              {dataGraphics[keyGraphic].points.map(
                (legend: any, index: number) => (
                  <StyledLegend key={`legend-${index}`}>
                    <StyledLegendShape colorShape={legend.backgroundColor} />
                    <StyledLabelLegend>{legend.label}</StyledLabelLegend>
                  </StyledLegend>
                )
              )}
            </StyledLegendContainer>
            <Bar
              data={{
                labels: months,
                datasets: dataGraphics[keyGraphic].points
              }}
              options={options}
              plugins={plugins}
            />
          </StyledGraphicsContainer>
        )
      })}
    </>
  )
}

export default Graphics
