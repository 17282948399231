import { MonthsTypes } from '../../../../types/Reports/ReportRewards'

export const MONTHS: MonthsTypes = {
  ['01']: 'Ene',
  ['02']: 'Feb',
  ['03']: 'Mar',
  ['04']: 'Abr',
  ['05']: 'May',
  ['06']: 'Jun',
  ['07']: 'Jul',
  ['08']: 'agosto',
  ['09']: 'Sep',
  ['10']: 'Oct',
  ['11']: 'Nov',
  ['12']: 'Dic'
}

export const DATA_GRAPHICS: any = {
  rewards: {
    points: [],
    label: 'Bonificaciones & BEES'
  },
  mpg: {
    points: [],
    label: 'Modelo para ganar'
  },
  loyalty: {
    points: [],
    label: 'Loyalty'
  },
  promotions: {
    points: [],
    label: 'Promociones'
  },
  frio: {
    points: [],
    label: 'Frio'
  },
  pos: {
    points: [],
    label: 'Usabilidad POS'
  }
}

export const PAGES_WITH_FILTER_IN_HEADER = ['sales', 'rewards']
