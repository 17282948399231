// Constants
import { MONTHS } from '../../data/constants/reports/reportsRewards'

// Types
import {
  DataBarTypes,
  RewardMonthReport,
  ChartTypes,
  AllTicksTypes,
  SetInfoOverReturnTypes,
  MonthsTypes,
  DataGraphicsNodeTypes
} from '../../types/Reports/ReportRewards'

export const setInfoOverBar = (chart: ChartTypes): SetInfoOverReturnTypes => {
  const ctx = chart.ctx
  ctx.save()

  chart.data.datasets.forEach((dataset: any, idx: number) => {
    const colorText = dataset.backgroundColor === '#ffc600' ? '#000' : '#0b4793'
    chart.getDatasetMeta(idx).data.forEach((datapoint: any) => {
      const { x, y } = datapoint.tooltipPosition()
      ctx.fillStyle = colorText
      ctx.fillText(convertToKilos(datapoint.$context?.raw), x, y - 12)
      ctx.textAlign = 'center'
      ctx.font = 'bold 12px Montserrat'
    })
  })
  return { chart, ctx }
}

export const setFormatAxisY = (
  value: number,
  _: number,
  allTicks: AllTicksTypes[]
): string | number => {
  if (allTicks[allTicks.length - 1].value > 2000) {
    return `${value / 1000} k`
  }
  return value
}

const convertToKilos = (number: number): number | string => {
  if (number < 1000) {
    return number.toFixed(2)
  }
  return (number / 1000).toFixed(2) + ' k'
}

const setDataCartones = (
  dataPerMonth: RewardMonthReport,
  DATA_MONEY: DataBarTypes
): boolean => {
  let isRewards = true
  if (dataPerMonth.typeOfTotalPoints === 'Cartones') {
    DATA_MONEY.label = 'Pesos / Cartones'
    isRewards = false
  }
  return isRewards
}

type barsDataType = {
  isRewards: boolean
  isFrioValidValue: boolean
  isPosValidValue: boolean
}

const setBarsData = (
  dataRewards: RewardMonthReport[],
  keyGraphics: string,
  DATA_MONTHS: string[],
  DATA_BAR: DataBarTypes,
  DATA_MONEY: DataBarTypes
): barsDataType => {
  let isRewards = true
  let isFrioValidValue = true
  let isPosValidValue = true
  dataRewards.forEach((dataPerMonth: RewardMonthReport) => {
    if (MONTHS[dataPerMonth.month as keyof typeof MONTHS]) {
      DATA_BAR.label =
        dataPerMonth.typeOfTotalPoints === 'Cartones'
          ? `Pesos / ${dataPerMonth.typeOfTotalPoints}`
          : 'Puntos Bees'

      isRewards = setDataCartones(dataPerMonth, DATA_MONEY)

      switch (keyGraphics) {
        case 'rewards':
          DATA_BAR.data.push(dataPerMonth.totalPoints)
          DATA_MONEY.data.push(dataPerMonth.totalPointsAsMonetaryAmount)
          DATA_MONTHS.push(MONTHS[dataPerMonth.month as keyof typeof MONTHS])
          break
        case 'mpg':
          DATA_BAR.data.push(dataPerMonth.mpgPoints)
          break
        case 'loyalty':
          DATA_BAR.data.push(dataPerMonth.loyaltyPoints)
          break
        case 'promotions':
          DATA_BAR.data.push(dataPerMonth.promotionsPoints)
          break
        case 'frio':
          if (dataPerMonth.frioPoints === 0) isFrioValidValue = false
          DATA_BAR.data.push(dataPerMonth.frioPoints)
          break
        case 'pos':
          if (dataPerMonth.posUsabilityPoints == 0) isPosValidValue = false
          DATA_BAR.data.push(dataPerMonth.posUsabilityPoints)
          break
        case 'beerU': {
          DATA_BAR.data.push(dataPerMonth.beeruPoints)
          break
        }
        default:
          break
      }
    }
  })
  return { isRewards, isFrioValidValue, isPosValidValue }
}

const orderMonths = (MONTHS: MonthsTypes, DATA_MONTHS: string[]) => {
  const months = Object.values(MONTHS)

  DATA_MONTHS.sort((a: string, b: string): number => {
    return months.indexOf(a) - months.indexOf(b)
  })
}

export const SET_DATA_GRAPHIC = (dataRewards: RewardMonthReport[]): any => {
  const DATA_MONTHS: string[] = []
  const DATA_GRAPHICS: DataGraphicsNodeTypes = {
    rewards: {
      points: [],
      label: 'Bonificaciones & Cartones'
    },
    mpg: {
      points: [],
      label: 'Modelo para ganar'
    },
    loyalty: {
      points: [],
      label: 'Loyalty'
    },
    promotions: {
      points: [],
      label: 'Promociones'
    },
    frio: {
      points: [],
      label: 'Frio'
    },
    pos: {
      points: [],
      label: 'Usabilidad POS'
    },
    beerU: {
      points: [],
      label: 'Beer U'
    }
  }

  for (const keyGraphics in DATA_GRAPHICS) {
    const DATA_BAR: DataBarTypes = {
      label: '',
      data: [],
      backgroundColor: '#ffc600',
      borderRadius: 3,
      borderSkipped: false,
      barPercentage: 0.5,
      categoryPercentage: 0.7
    }
    const DATA_MONEY: DataBarTypes = {
      label: 'Pesos',
      data: [],
      backgroundColor: '#0b4793',
      borderRadius: 3,
      borderSkipped: false,
      barPercentage: 0.5,
      categoryPercentage: 0.7
    }

    const { isRewards, isFrioValidValue, isPosValidValue } = setBarsData(
      dataRewards,
      keyGraphics,
      DATA_MONTHS,
      DATA_BAR,
      DATA_MONEY
    )

    switch (keyGraphics) {
      case 'rewards':
        if (isRewards) {
          DATA_GRAPHICS[keyGraphics].points.push(DATA_BAR)
          DATA_GRAPHICS.rewards.label = 'Bonificaciones & BEES'
        }
        DATA_GRAPHICS[keyGraphics].points.push(DATA_MONEY)
        break
      case 'frio':
        DATA_GRAPHICS[keyGraphics as keyof typeof DATA_GRAPHICS].points.push(
          DATA_BAR
        )
        if (!isFrioValidValue)
          delete DATA_GRAPHICS[keyGraphics as keyof typeof DATA_GRAPHICS]
        break
      case 'pos':
        DATA_GRAPHICS[keyGraphics as keyof typeof DATA_GRAPHICS].points.push(
          DATA_BAR
        )
        if (!isPosValidValue)
          delete DATA_GRAPHICS[keyGraphics as keyof typeof DATA_GRAPHICS]
        break
      default:
        DATA_GRAPHICS[keyGraphics as keyof typeof DATA_GRAPHICS].points.push(
          DATA_BAR
        )
        break
    }
  }

  orderMonths(MONTHS, DATA_MONTHS)

  return { DATA_GRAPHICS, DATA_MONTHS }
}
