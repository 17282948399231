// Package
import styled from 'styled-components'

export const StyledRewardsButtonBack = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;
  #button-icon-back-sales-report {
    margin: 12px 0 0 24px;
  }
  h1 {
    width: 230px;
    margin: 14px 9px 0 9px;
  }
`

export const StyledGraphicsContainer = styled.div`
  padding: 0px 16px 12px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
